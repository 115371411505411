<template>
    <AppScopeForm v-if="scope" :scope="scope" :isEdit="false"/>
</template>

<script>

import AppScopeForm from "@/modules/Admin/Components/AppScopeForm.vue";

export default {
  name: "CreateAppScopePage",
  components: {AppScopeForm},
  data() {
    return {
      scope: {
          scope_type: {
              id: 1,
              name: 'application'
          },
          object_id: null,
          object_uuid: null,
          enabled_features: []
      }
    }
  },
};
</script>
